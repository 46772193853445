import React, {Component} from "react"
import Slider from "react-slick"
import {graphql, Link} from "gatsby"
import {Player, ControlBar} from "video-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./index.module.scss"
import Img from "gatsby-image"
import Cookies from 'universal-cookie'
/*import queryString from "query-string"
import Alerta from "../components/alert"*/
import {ParallaxBanner} from "react-scroll-parallax";

import ReactWOW from "react-wow/"

import "slick-carousel/slick/slick.css"

import MexJet from '../images/icons/logo_Mexjet.svg'
import Beechcraft from '../images/icons/logo_BeechcraftTextron.svg'
import ServiceCenter from '../images/icons/logo_ServiceCenter.svg'
import FBO from '../images/icons/icon_FBO.svg'
import PreOwned from '../images/icons/icon_Preowned.svg'
import Marine from '../images/icons/logo_Marine.svg'
import IconHistory from '../images/icons/historia_icon_rombo.svg'
import IconHistorySelected from '../images/icons/historia_icon_rombo_seleccionado.svg'
import VideoHome from '../video/ale-2020.mp4'
import VideoCovid from '../video/Master_AleCovid19_Draft2.mp4'
import "video-react/dist/video-react.css"
import Form from '../components/form'
import imgPage from "../images/headers/Logos_Ale_Blue.svg";
import Header from "../components/header";
import LogoTextron from "../images/icons/logo_Textron.png";

export default ({data, location}) => {
    /*const varsLocation = location.search ? queryString.parseUrl(location.search) : null;*/
    const cookies = new Cookies();
    var visited = cookies.get('visited');
    if (typeof visited === 'undefined') {
        cookies.set('visited', true, {path: '/'});
    }
    //Si se requiere solo mostrar una vez usar visited
    return (
        <Layout>
            <SEO title="Inicio Aerolineas Ejecutivas"/>
            {/*<Alerta message={varsLocation ? varsLocation.query.message : null}/>*/}
            <Header LogoPage={imgPage}/>
            <section className={style.headerHome}>
                <Img className={style.img} fluid={data.portada.childImageSharp.fluid}/>
                {/*<PlayerHome/>*/}
            </section>
            <Form/>

            <section className={style.bannerHome}>
                <h1>Sólo Ale lo tiene todo</h1>
                <p><b>Nuestras Áreas de negocio</b></p>
            </section>

            <ReactWOW animation='fadeInUp'>
                <section className={style.brandsHome}>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <MexJet/>
                            <p><b>JetCard / Fractional Ownership</b></p>
                            <p>El programa pionero de aviación fraccional. Por hora o en copropiedad, vuele en la
                                dimensión más adecuada en alguna de nuestras más de 30 aeronaves.</p>
                            <Link to='/mexjet'>Conozca Más</Link>
                        </div>
                    </div>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <img className={style.textron} src={LogoTextron} alt={'Textron'}/>
                            <p><b>Piston / Turboprops / Jets</b></p>
                            <p>Representamos a los fabricantes más destacados de la industria.</p>
                            <Link to='/dealerships'>Conozca Más</Link>
                        </div>
                    </div>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <PreOwned/>
                            <p><b>Brokerage</b></p>
                            <p>Dominamos el mercado local y global para brindarle las mejores oportunidades u obtener el
                                mejor valor por su aeronave.</p>
                            <Link to='/preowned'>Conozca Más</Link>
                        </div>
                    </div>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <FBO/>
                            <p><b>Ground Services / Management</b></p>
                            <p>Nos encargamos de la administración y custodia de su aeronave.</p>
                            <Link to='/fbo-management'>Conozca Más</Link>
                        </div>
                    </div>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <ServiceCenter/>
                            <p><b>Maintenance / Paint / Interiors</b></p>
                            <p>Ale Service Center se ha especializado en brindar un servicio de excelencia para
                                aeronaves.</p>
                            <Link to='/service-center'>Conozca Más</Link>
                        </div>
                    </div>
                    <div className={style.brand}>
                        <div className={style.content}>
                            <Marine/>
                            <p><b>Yacht Sales / Fractional Ownership</b></p>
                            <p>Representamos a FIM (Fabbrica Italiana Motoscafi) experimentado astillero Italiano que produce modelos de yates que combinan lujo y deportividad.
                                </p>
                            <Link to='/marine'>Conozca Más</Link>
                        </div>
                    </div>
                </section>
            </ReactWOW>

            <ReactWOW animation='fadeIn'>
                <ParallaxBanner
                    className={style.homeTurbine}
                    layers={[{image: data.footer.publicURL, amount: 1.0}]}
                    style={{maxHeight: '400px'}}
                >
                </ParallaxBanner>
                <section className={style.storyLine}>
                    <h2>Nuestros 50 años de historia</h2>
                    <TimeLine data={data}/>
                </section>
            </ReactWOW>

        </Layout>
    )
}


/*export class PlayerHome extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null
        };
        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }

    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.setState({vidStatus: false})
    }

    render() {
        return (
            <Player
                onEnded={this.onEnded}
                className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                src={VideoHome}
                ref={player => {
                    this.player = player;
                }}
            >
                <ControlBar disableCompletely={false}/>
                <button className={style.button} onClick={this.startVideo}>Play Video</button>
            </Player>
        )
    }
}*/

export class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            currentEl: 0,
            data: props.data
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            activeSlide: 0,
            activeSlide2: 0
        });
    };

    render() {
        return (
            <div className={style.sliders}>
                <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={5}
                    centerMode={true}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    className={style.dates}
                    beforeChange={(current, next) => this.setState({activeSlide: next})}
                    afterChange={current => this.setState({activeSlide2: current})}
                >
                    <div className={this.state.activeSlide === 0 ? `${style.item} ${style.big}` : style.item}>
                        <h3>1968</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 1 ? `${style.item} ${style.big}` : style.item}>
                        <h3>1980</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 2 ? `${style.item} ${style.big}` : style.item}>
                        <h3>1994</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 3 ? `${style.item} ${style.big}` : style.item}>
                        <h3>1997</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 4 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2000</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 5 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2004</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 6 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2007</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 7 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2009</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 8 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2010</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 9 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2011</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 10 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2012</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 11 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2014</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 12 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2016</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 13 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2017</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 14 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2018</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 15 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2019</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 16 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2020</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                    <div className={this.state.activeSlide === 17 ? `${style.item} ${style.big}` : style.item}>
                        <h3>2023</h3>
                        <div className={style.icon}>
                            <IconHistory/>
                            <IconHistorySelected/>
                        </div>
                    </div>
                </Slider>

                <Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    className={style.info}
                >
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto1968.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Fundación del primer taxi aéreo en México llamado Aéreo Taxis de México.</p>
                        </div>
                    </div>

                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto1980.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Se nombra a la compañía como Aerolíneas Ejecutivas.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto1994.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Construcción de nuestro FBO en Toluca y anuncio de la representación de Hawker
                                Beechcraft.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto1997.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Lanzamiento del Programa de MexJet.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2000.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Inicio del Servicio Autorizado de MRO por Hawker Beechcraft.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2004.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Inicio de la representación de Agusta Westland.</p>
                        </div>
                    </div>

                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2007.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Representación Enstrom Helicópteros.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2009.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Creación del Área de Negocio PreOwned, creación de la JetCard para el programa de MexJet
                                e inauguración de nuestras instalaciones y FBO en Monterrey.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2010.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Representación de Honda en México.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2011.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Representación de Princess Yachts en México y apertura de nuestras bases en Mérida,
                                Yucatán, Culiacán y Sinaloa.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2012.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Apertura y comienzo de operaciones de MRO Ale Service Center en Monterrey, creación
                                del programa de navegación fraccional Share at Sea de nuestra área de Ale
                                Marine y apertura de nuestra base en el Bajío.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2014.childImageSharp.fluid}/>
                        </div>yac
                        <div className={style.text}>
                            <p>Se entregan 60 aeronaves a La Secretaría de Defensa por medio de nuestra
                                representación con Beechcraft Defense.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2016.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Cambia la Representación de Agusta Westland a Leonardo Helicopters.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                           
                        </div>
                        <div className={style.text}>
                            <p>Cierre de representación en México de Textron Aviation. Alianza estratégica de Ale
                                PreOwned con JetCraft y el 20 aniversario de MexJet.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2018.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Celebramos 50 aniversario de Aerolíneas Ejecutivas.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2019.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Sólo ALE lo tiene todo.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2020.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Trazando la ruta del MMXX.</p>
                        </div>
                    </div>
                    <div className={style.date}>
                        <div className={style.img}>
                            <Img className={style.image} fluid={this.state.data.foto2023.childImageSharp.fluid}/>
                        </div>
                        <div className={style.text}>
                            <p>Cumplimos 55 años.</p>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}


export const query = graphql`
    query ImagesHome {
    portada: file(relativePath: {eq: "home/latitude_pagina_inicio.jpg"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 1920) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1968: file(relativePath: {eq: "home/1968_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1980: file(relativePath: {eq: "home/1980_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1994: file(relativePath: {eq: "home/1994_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1997: file(relativePath: {eq: "home/1997_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2000: file(relativePath: {eq: "home/2000_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2004: file(relativePath: {eq: "home/2004_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2007: file(relativePath: {eq: "home/2007_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2009: file(relativePath: {eq: "home/2009_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2010: file(relativePath: {eq: "home/2010_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2011: file(relativePath: {eq: "home/2011_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2012: file(relativePath: {eq: "home/2012_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2014: file(relativePath: {eq: "home/2014_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2016: file(relativePath: {eq: "home/2016_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2017: file(relativePath: {eq: "home/2017_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2018: file(relativePath: {eq: "home/2018_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2019: file(relativePath: {eq: "home/2019_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2020: file(relativePath: {eq: "home/2020_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2023: file(relativePath: {eq: "home/2023_home_historia.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    footer: file(relativePath: {eq: "home/foto_galeria.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`

